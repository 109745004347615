<template>
    <div>
        <app-paginated-table :fields="fields" :items="items" :current-page="current_page" :total-pages="total_pages" :total="total_records" :loading="loading" @onPageChange="onPageChange" :mobileHeaders="['invoice_no','amount','actions']" :placeRight="2" @onLoadMore="loadMoreItems">
            <template #header>
                <b-tabs @input="onTabChange">
                    <b-tab title="Paid" value="sent" active></b-tab>
                    <b-tab title="Recieved"  v-if="getUserRole()!='client'" value="received"></b-tab>
                </b-tabs>   
            </template>
            <template #filters>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="getPaymentsList()"></app-text-input>
                    </div>
                </div>
            </template>
            <template #head(actions)>
                {{ '' }}
            </template>
            <template #cell(invoice_no)="data">
                {{ data.item.invoice.invoiceNo}}
            </template>
            <template #cell(customers)="data">
                {{ tab==1?getFullName(data.item.receiver):getFullName(data.item.sender)}}
            </template>
            <template #cell(date)="data">
                {{ getFormatedDate(data.item.date,"DD MMM YYYY") }}
            </template>
            <template #cell(amount)="data">
                {{ data.item.amount | currency }}
            </template>
            <template #cell(actions)="data">
                <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Block Level Dropdown Menu"
                right
                variant="none"
                size="sm"
                no-caret
                >
                <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                    />
                </template>
                    <b-dropdown-item @click="getReciept(data.item.invoice._id,data.item.stripePaymentId)" >Download Reciept</b-dropdown-item>
                </b-dropdown>
                
            </template>
        </app-paginated-table>
    </div>
</template>
<script>
import {BTabs,BTab,BCard,BTableLite, BDropdown, BDropdownItem, BBadge,BSidebar, BAvatarGroup, BAvatar} from 'bootstrap-vue'
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import AppConfirmModal from '@/components/app-components/AppConfirmModal.vue'
import UpdateUser from '@/components/functional-components/admin/users/UpdateUser.vue'
import UserDetails from '@/components/functional-components/admin/users/UserDetails.vue'
import {get_payments_list,get_payment_receipt} from '@/apis/others/payments'
import Ripple from 'vue-ripple-directive'
import {getUserData} from '@/auth/utils'
import moment from 'moment'
export default{
    directives:{
        Ripple
    },
    components:{
        BTabs,
        BTab,
        BCard,
        BTableLite,
        AppPaginatedTable,
        AppTextInput,
        BDropdown,
        BDropdownItem,
        BBadge,
        AppSelectBox,
        AppConfirmModal,
        UpdateUser,
        BSidebar,
        UserDetails,
        BAvatarGroup, 
        BAvatar
    },
    data(){
        return{
            search:'',
            type:'sent',
            fields:[
                {
                    key:'invoice_no',
                    label:'Invoice #'
                },
                {
                    key:'amount',
                    label:'Amount'
                },
                {
                    key:'customers',
                    label:'Customers'
                },
                {
                    key:'date',
                    label:'Date'
                },
                {
                    key:'actions'
                }
            ],
            items: [],
            filters:{
                page:1,
                limit:10,
                search:'',
            },
            current_page:1,
            total_records:0,
            total_pages:0,
            loading:false,
            loadMore:false
        }
    },
    mounted(){
        // this.getUsers()
        this.getPaymentsList()
    },
    methods:{
        onTabChange(tab){
            this.type=tab==1?'received':'sent'
            this.filters.page=1;
            this.filters.search=''
            this.getPaymentsList()
        },
        onPageChange(page){
            this.filters.page=page
            this.getPaymentsList()
        },
        getPaymentsList(){
            this.loading=true
            get_payments_list(this.type, this.filters).then(resp=>{
                this.loading=false
                this.items=this.loadMore?[...this.items,...resp.data.payments]:resp.data.payments
                this.current_page=Number(resp.data.currentPage)
                this.total_records=resp.data.paymentsCount
                this.total_pages=resp.data.paymentsCount>0?resp.data.paymentsCount/this.filters.limit:0
                this.loadMore=false
            }).catch(err=>{
                this.loading=false
            })
        },
        getFullName(user){
            return `${user.first_name.charAt(0).toUpperCase()}${user.first_name.slice(1)} ${user.last_name.charAt(0).toUpperCase()}${user.last_name.slice(1)}`
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        getReciept(id, payment_id){
            get_payment_receipt({paymentId:payment_id,invoiceId:id }).then(resp=>{
               this.download(resp.data)
            })
        },
        download(url){
            var link = document.createElement("a");
            link.setAttribute('download','payment_receipt');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        getUserRole(){
            const user=getUserData()
            return user.role
        },
        loadMoreItems(){
            this.loadMore=true;
            this.filters.page+=1
            this.getPaymentsList()
        }
    }
}
</script>